.webhook-tab-list {
  align-items: flex-start;
  background-color: #282828;
  display: flex;
  flex-direction: column;
  margin-left: 330px;
  overflow: auto;
  width: 100%;
  height: 100%;

  /* Background */
  background-color: #161616;
  opacity: 1;
  background-image: radial-gradient(#bacddb11 0.75px, transparent 0.75px), radial-gradient(#bacddb11 0.75px, #1a1a1a 0.75px);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}

.webhook-top {
  align-items: center;
  background-color: #1c1c1c;
  border: none;
  border-bottom: solid;
  border-width: 0px;
  border-color: var(--dark-grey);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0),
  0px 8px 12px -10px rgba(186, 205, 219, 0.1),
  0px -10px 10px -10px rgba(0, 0, 0, 0);
  display: flex;
  position: fixed;
  width: 100%;
  height: 50px;
  z-index: 2;
}

.webhook-title {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  margin: 16px 10px 16px 10px;
}

.webhook-bottom {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 62px;
  margin-left: 12px;
  width: 98%;
}

.webhook {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  background-color: #222222;
  border: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #3b3b3b;
  margin-bottom: 10px;
  height: 40px;
  width: 100%;
}

.webhook-form {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 32px;
  width: 100%;
}

.webhook-form input {
  color: var(--foggy-grey);
  background-color: #1a1a1a;
  border: none;
  border-radius: 6px;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  height: 20px;
  width: 100%;
}

.webhook input:focus {
  outline-color: #5f5f5f;
  outline-style: solid;
  outline-width: 1.2px;
}

.webhook-form button {
  align-items: center;
  background-color: #3b3b3b;
  border-radius: 4px;
  color: var(--foggy-grey);
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 6px;
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
}

.webhook-form button:hover {
  background-color: #2e2e2e;
}

.webhook-edit-img {
  width: 135%;
}

.webhook-save-img {
  width: 200%;
}

.webhook-delete-img {
  width: 148%;
}

.webhook-add {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  background-color: rgb(42, 42, 42);
  border: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #515151;
  margin-bottom: 10px;
  height: 40px;
  width: 100%;
}

.webhook-add-form {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 32px;
  width: 100%;
}

.webhook-add-form input {
  color: var(--foggy-grey);
  background-color: #1d1d1d;
  border: none;
  border-radius: 6px;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  height: 20px;
  width: 100%;
}

.webhook-add input:focus {
  outline-color: #5f5f5f;
  outline-style: solid;
  outline-width: 1.2px;
}

.webhook-add input::placeholder {
  font-size: 16px;
  font-family: 300;
}

.webhook-add-form button {
  align-items: center;
  background-color: var(--silver);
  border-radius: 4px;
  color: var(--foggy-grey);
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 6px;
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
}

.webhook-add-form button:hover {
  background-color: var(--silver-hover);
}

.webhook-add-img {
  width: 238%;
}