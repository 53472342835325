/* Bottom Section */
@media (max-width: 768px) {
  .bottom-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10em;
    padding-top: 4em;
    width: 90%;
    height: auto;
  }

  .bottom-section-title {
    font-size: 5vmin;
    text-align: center;
    margin: 0;
    width: 90%;
  }

  .bottom-section-subtitle {
    color: rgba(202, 202, 202, 0.5);
    font-size: 2.2vmin;
    text-align: center;
    margin: 0;
    width: 85%;
  }

  .faq-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 4vmin;
    width: 90%;
    height: auto;
  }

  .faq-container h3 {
    font-size: 2.8vmin;
  }

  .faq-container p {
    font-size: 2vmin;
    margin: 0;
  }

  .faq-container .title {
    color: var(--foggy-grey);
    cursor: auto;
  }

  .faq-container .title:hover {
    color: var(--foggy-grey);
  }

  .gibook-link {
    display: flex;
    color: var(--foggy-grey);
    justify-content: center;
    width: 100%;
  }

  .faq-container .gibook-link:hover {
    color: var(--foggy-grey);
  }

  .gibook-link a {
    align-items: center;
    cursor: pointer;
    display: block;
    top: 0px;
    margin-left: 0;
    margin-right: 0;
    position: relative;
    padding: 0;
    width: 14vmin;
  }

  .gibook-link a:hover {
    top: 1px;
  }

  .gibook-link a span {
    color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    font-size: 3vmin;
    top: -2px;
    margin-right: 10px;
    margin-left: 6px;
    position: relative;
    padding: 0;
  }

  .qa-first,
  .qa-second,
  .qa-third,
  .qa-fourth,
  .qa-fifth {
    align-items: center;
    display: flex;
    background-color: var(--silver);
    border-radius: 4px;
    box-shadow: 0px 0px 100px 10px #bacddb11;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 10px;
    padding-top: 6px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-bottom: 22px;
    width: 98%;
    height: auto;
  }

  .qa-first:hover h3,
  .qa-second:hover h3,
  .qa-third:hover h3,
  .qa-fourth:hover h3,
  .qa-fifth:hover h3 {
    color: #34343499;
  }

  .qa-first h3,
  .qa-second h3,
  .qa-third h3,
  .qa-fourth h3,
  .qa-fifth h3 {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    transition: color .2s;
    pointer-events: none;
  }

  .faq-container h3:hover {
    color: #34343499;
  }

  .faq-container p {
    display: none;
    pointer-events: none;
    text-align: center;
  }

  .faq-container img {
    padding-right: 10px;
    pointer-events: none;
    width: 2vmin;
  }

  #faq>div>h3.gibook-link.aos-init.aos-animate {
    margin-top: 10px;
  }
}