.access {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4px;
  user-select: text;
  width: 100%;
}

.add-user-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;
  height: 40px;
  width: 90%;
}

.add-button {
  align-items: center;
  background-color: var(--silver);
  border-radius: 8px;
  color: var(--dark-grey);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  user-select: none;
  width: 118px;
  height: 30px;
  transition: background-color .2s;
}

.add-button:hover {
  background-color: var(--silver-hover);
}

.user-plus-img {
  pointer-events: none;
  margin-right: 8px;
  width: 16px;
}

.add-user-tab {
  align-items: center;
  display: flex;
  background-color: rgba(22, 22, 22, .5);
  backdrop-filter: blur(2px);
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
}

.add-user-field {
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.04) ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  width: 360px;

  /* Background */
  background-color: #161616;
  opacity: 1;
  background-image: radial-gradient(#bacddb11 0.75px, transparent 0.75px), radial-gradient(#bacddb11 0.75px, #161616 0.75px);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}

.add-user-from {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.user-input-field {
  background-color: var(--dark-grey);
  border: none;
  border-radius: 8px;
  color: var(--snow-drift);
  font-size: 16px;
  font-weight: 300;
  font-family: 'Kumbh Sans', sans-serif;
  margin-bottom: 4px;
  padding-left: 8px;
  height: 30px;
  width: 240px;
}

.user-input-field:focus {
  outline-color: #BACDDB;
  outline-width: 1px;
  outline-style: solid;
}

.user-input-field::placeholder {
  user-select: none;
}

.select-option {
  text-align: center;
  background-color: var(--silver);
  border: none;
  border-radius: 8px;
  color: var(--dark-grey);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Kumbh Sans', sans-serif;
  outline: none;
  padding-left: 12px;
  user-select: none;
  height: 20px;
  width: 146px;
}

.select-option:hover {
  background-color: var(--silver-hover);
}

.select-option option {
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Kumbh Sans', sans-serif;
}

.add-user-from button {
  margin-top: 6px;
  height: 20px;
  width: 146px;
}

.add-button img {
  width: 22px;
}

.main-table-container {
  border-radius: 10px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: scroll;
  width: 100%;
}

.table-container {
  background-color: #bacddb06;
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px 20px 20px 20px;
  max-width: 90%;
  height: auto;
  width: 100%;
}

.table-container th {
  pointer-events: none;
  user-select: none;
  border-radius: 6px;
  background-color: #161616;
  opacity: 1;
  background-image: radial-gradient(#bacddb11 0.75px, transparent 0.75px), radial-gradient(#bacddb11 0.75px, #161616 0.75px);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}

.table-container td {
  border-bottom: 1px solid #ffffff09;
  border-radius: 6px;
  padding: 8px;
}

.table-container th,
.table-container td {
  min-width: 100px;
}

.table-container tr:nth-child(even) {
  background-color: #f2f2f201;
}

.table-container tr:hover {
  background-color: #bacddb02;
}

.table-container th, 
.table-container td {
  padding: 16px;
  text-align: left;
}

.table-container td {
  padding: 8px;
  text-align: left;
}

.info-graph p {
  color: var(--snow-drift);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .6px;
  margin: 0;
}

.user-info p {
  color: var(--snow-drift);
  font-size: 15.2px;
  font-weight: 300;
  letter-spacing: .6px;
  margin: 0;
  text-transform: capitalize;
}

.user-info td:nth-child(1) {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.user-image {
  border-radius: 100%;
  pointer-events: none;
  user-select: none;
  height: 48px;
  width: 48px;
}

.user-name {
  padding-left: 16px;
}

.user-action {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 128px;
}

.delete-button {
  align-items: center;
  background-color: var(--silver);
  border-radius: 8px;
  color: var(--dark-grey);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  user-select: none;
  width: 34px;
  height: 30px;
  transition: background-color .2s;
  transform: translate(0, -50%);
}

.delete-button:hover {
  background-color: var(--silver-hover);
}

.delete-img {
  pointer-events: none;
  width: 16px;
}








.update-button {
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  color: var(--snow-drift);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 00;
  justify-content: center;
  user-select: none;
  width: 68px;
  height: 30px;
  transition: background-color .2s, color .2s;
  transform: translate(0, -50%);
  position: relative;
  z-index: 1;
}

@media (hover: hover) and (pointer: fine) {
  .update-button:hover {
    background-color: var(--silver);
    color: var(--dark-grey);
  }  
}

.roles-menu {
  align-items: flex-start;
  background-color: var(--dark-grey);
  border-radius: 12px;
  box-shadow: 0 0 9px 1px #56595c3e;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  padding-top: 28px;
  padding-bottom: 6px;
  margin-bottom: 152px;
  margin-left: -22px;
  width: 126px;
  height: auto;
  z-index: 2;
}

.close-box {
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  top: 6px;
  right: 6px;
  position: absolute;
  width: 22px;
  height: 22px;
}

.close-img {
  pointer-events: none;
  user-select: none;
  width: 22px;
}

@media (hover: hover) and (pointer: fine) {
  .close-box:hover, .close-img:hover {
    filter: brightness(0) saturate(100%) invert(27%) sepia(40%) saturate(3110%) hue-rotate(340deg) brightness(85%) contrast(89%);
  }
}

.role {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  width: 100%;
}

/* Checkbox style */
.role input {
  appearance: none;
  background-color: #dfe1e4;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  margin-right: 2px;
  position: relative;
  width: 30px;
}

.role input::before {
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.role input,
.role input::after {
  transition: all 100ms ease-out;
}

.role input::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.role input[type=checkbox] {
  cursor: default;
}

.role input:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
}

.role input:checked {
  background-color: #6e79d6;
}

.role input:checked::after {
  background-color: #fff;
  left: 13px;
}

.role :focus:not(.focus-visible) {
  outline: 0;
}

.role input:checked:hover {
  background-color: #535db3;
}
/* Checkbox style */

.role p {
  color: var(--snow-drift);
  pointer-events: none;
  user-select: none;
  font-weight: 500;
  font-size: 16px;
  margin-left: 2px;
}

@media (max-width: 910px) {
  .roles-menu {
    position: fixed;
    margin: 0;
    bottom: 12px;
    left: 50%;
    transform: translate(-50%, -6%);
    width: 200px;
    height: 100px;
  }

  .close-box {
    width: 30px;
    height: 30px;
  }

  .close-img {
    width: 30px;
  }

  .role p {
    font-weight: 500;
    font-size: 24px;
  }

  .role input {
    margin-left: 2px;
    margin-right: 8px;
    height: 26px;
    width: 38px;
  }

  .role input::after {
    height: 20px;
    width: 20px;
  }
}







.access-spin {
  display: block;
}

.access-spin .access-spinner{
  display: block;
  position: relative;
  top: 4px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.access-spin .access-spinner::before {
  animation: .8s linear infinite spinner;
  border-radius: 50%;
  border: solid .25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 2.2em;
  height: 2.2em;
}