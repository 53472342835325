.root-adidas {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: -3.6px;
  margin-bottom: 52px;
  user-select: text;
  width: 100%;
}

.region-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
}

.region-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  width: 100%;
}

.region-title {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 1.6px;
  margin: 14px 0 14px 0;
  user-select: none;

  background-color: #343434;
  border-radius: 8px;
  text-align: center;
  width: 100%;

  transition: background-color .2s;
}

.region-title-hot1 {
  background-color: #3f3f3f;
  font-weight: 500;
}

.region-title:hover {
  background-color: #3f3f3f;
  cursor: pointer;
}

.region-webhook {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.region-webhook-folded {
  display: none;
}

.webhook {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}

@media (min-width: 1024px) {
  .webhook {
    width: 74%;
  }
}

.webhook-status {
  color: var(--foggy-grey);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.6px;
  margin: 0;
  user-select: none;
}

.webhook-form {
  align-items: center;
  background-color: #34343434;
  display: flex;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 2px;
  height: 56px;
  width: 92%;
}

.webhook-grid {
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 100%;
}

.webhook-label {
  color: var(--foggy-grey);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.6px;
  padding-left: 2px;
}

.webhook-row {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.webhook-input {
  color: var(--foggy-grey);
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 16px;
  padding-left: 6px;
  background-color: #343434;
  border: none;
  border-radius: 8px;
  width: 100%;
}

.webhook-input::placeholder {
  color: var(--foggy-grey);
}

.webhook-input:focus {
  outline: solid;
  outline-width: .4px;
  outline-color: var(--silver);
}

.webhook-edit-btn,
.webhook-save-btn,
.webhook-delete-btn {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: none;
  margin-left: 2px;
  margin-right: 2px;
  height: 30px;
  max-height: 30px;
  width: 38px;
  max-width: 30px;
}

.edit-img,
.cloud-upload-img,
.delete-img {
  width: 100%;
  user-select: none;
  pointer-events: none;
  filter: brightness(0) saturate(100%) invert(70%) sepia(20%) saturate(218%) hue-rotate(165deg) brightness(96%) contrast(90%);
}

.edit-img {
  width: 20px;
}

.cloud-upload-img {
  width: 22px;
}

.delete-img {
  width: 20px;
}

@media (hover: hover) and (pointer: fine) {
  .webhook-edit-btn:hover .edit-img,
  .webhook-save-btn:hover .cloud-upload-img,
  .webhook-delete-btn:hover .delete-img {
    filter: brightness(0) saturate(100%) invert(70%) sepia(20%) saturate(218%) hue-rotate(165deg) brightness(56%) contrast(90%);
  }
}

.access-spin {
  display: block;
}

.access-spin .access-spinner{
  display: block;
  position: relative;
  top: 4px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.access-spin .access-spinner::before {
  animation: .8s linear infinite spinner;
  border-radius: 50%;
  border: solid .25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 2.2em;
  height: 2.2em;
}

.bottom-container {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  height: 72px;
  min-height: 72px;
  width: 100%;
  animation-name: in;
  animation-duration: .2s;
  z-index: 2;
}

@keyframes in {
  0% {bottom: -100px;}
  100% {bottom: 0;}
}

.menu-box {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: scroll !important;
  height: 58px;
  width: 100%;
}

.menu-btn {
  align-items: center;
  background-color: transparent;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 24px;
  margin-bottom: 20px;
  position: absolute;
  left: 0;
  height: 52px;
  width: 52px;
}

@media (min-width: 1024px) {
  .menu-btn {
    margin-left: 120px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .menu-btn:hover {
    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(358deg) brightness(108%) contrast(101%);
  }
}

.menu-dots-img {
  border-radius: 100%;
  pointer-events: none;
  user-select: none;
  width: 58px;
}

.upload-container {
  align-items: center;
  background-color: #1e1e1ea3;
  backdrop-filter: blur(8px);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 -20px 400px 10px rgb(0, 0, 0, .4);
  display: none;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: -620px;
  height: auto;
  width: 100%;
  transition: bottom .4s;
  z-index: 6;
}

@media (min-width: 1024px) {
  .upload-container {
    background-color: #1e1e1e;
    backdrop-filter: none;
    box-shadow: none;
    margin-left: auto;
    margin-right: auto;
    width: 660px;
  }
}

.upload-background {
  background-color: #c96767;
  height: 300px;
  width: 100%;
  z-index: 20;
  user-select: none;
  pointer-events: none;
}

.upload-container-show {
  animation-name: upload-container-in;
  animation-duration: .4s;
  bottom: 0;
  display: flex;
}

@keyframes upload-container-in {
  0% {bottom: -620px;}
  100% {bottom: 0;}
}

.upload-form-title {
  color: var(--foggy-grey);
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 1.6px;
  margin-top: 22px;
  margin-bottom: 0;
  text-align: center;
  user-select: none;
  width: 100%;
}

.upload-form {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 8px;
  width: 100%;
  z-index: 10;
}

@media (min-width: 768px) {
  .upload-form {
    width: 600px;
  }
}

.upload-input-name,
.upload-input-webhook {
  color: var(--foggy-grey);
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 18px;
  padding-left: 6px;
  background-color: #454545;
  border: none;
  border-radius: 8px;
  margin-top: 6px;
  height: 34px;
  width: 100%;
}

.upload-input-name::placeholder,
.upload-input-webhook::placeholder {
  color: #cacaca9d;
  font-size: 18px;
}

.upload-input-name:focus,
.upload-input-webhook:focus {
  outline: solid;
  outline-width: .4px;
  outline-color: var(--silver);
}

.upload-select-region,
.upload-select-status {
  color: var(--foggy-grey);
  cursor: pointer;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding-left: 6px;
  background-color: #454545;
  border: none;
  border-radius: 16px;
  margin-top: 4px;
  text-align: center;
  user-select: none;
  height: 34px;
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .upload-select-region:hover,
  .upload-select-status:hover {
    background-color: #414141;
  }
}

.upload-select-region:focus,
.upload-select-status:focus {
  outline: 0;
}

.upload-select-region {
  margin-top: 8px;
}

.upload-submit-btn {
  background-color: var(--silver);
  border: none;
  border-radius: .6em;
  color: #343434;
  cursor: pointer;
  display: block;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: .4px;
  margin-top: 8px;
  position: relative;
  text-transform: uppercase;
  user-select: none;
  width: 100%;
  height: 34px;
  outline: none;
}

.upload-cancel-btn {
  background-color: transparent;
  border: none;
  border-radius: .6em;
  color: #D0383B;
  cursor: pointer;
  display: block;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .4px;
  margin-top: 6px;
  margin-bottom: 2px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-transform: uppercase;
  user-select: none;
  width: 100px;
  height: 20px;
  outline: none;
}

@media (hover: hover) and (pointer: fine) {
  .upload-submit-btn:hover {
    background-color: var(--silver-hover);
  }

  .upload-cancel-btn:hover {
    color: #d0383bde;
  }
}

.upload-submit-btn:focus {
  outline-style: none;
}

.first-background {
  cursor: pointer;
  position: absolute;
  top: 140px;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.second-background {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.spin {
  display: block;
}

.spin .spinner{
  top: 4px;
  width: 26px;
  display: block;
  position: absolute;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin .spinner::before {
  animation: .8s linear infinite spinner;
  border-radius: 50%;
  border: solid .25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 1.2em;
  height: 1.2em;
}

*, *::before, *::after {
  box-sizing: border-box;
}




.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 18px;
  top: 18px;
  z-index: 4;
}

.info-tab {
  /* background-color: #7ee24f; */
  align-items: center;
  border-radius: 8px;
  box-shadow: inset 0px 1px 12px 2px rgb(0, 0, 0, .4);
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  padding: 4px;
  user-select: none;
  height: auto;
  width: 210px;
  z-index: 4;
}

@keyframes info-tab-position {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.info-img {
  margin-right: 6px;
  user-select: none;
  pointer-events: none;
  width: 22px;
}

.info-desc {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  width: 174px;
  word-wrap: break-word;
}

/* SKUs */
.form-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 22px;
}

.form-container label {
  color: var(--foggy-grey);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.6px;
}

.form-container input {
  background-color: #343434;
  border: none;
  border-radius: 6px;
  color: var(--foggy-grey);
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 18px;
  font-weight: 300;
  padding-right: 50px;
  height: 32px;
  width: 272px;
}

.form-container input:focus {
  outline-color: #5f5f5f;
  outline-style: solid;
  outline-width: .2px;
}

.form-container input::placeholder {
  font-size: 18px;
  font-family: 300;
}

.form-container textarea {
  border: none;
  border-radius: 8px;
  background-color: #343434;
  color: var(--foggy-grey);
  float: left;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 16px;
  padding-left: 6px;
  min-width: 180px;
  max-width: 480px;
  min-height: 88px;
  max-height: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 300px;
  height: 140px;
}

.form-container ::-webkit-scrollbar {
  width: 8px !important;
}

.form-container ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #5d5d5d !important;
}

.form-container textarea:focus {
  outline-color: #BACDDB;
  outline-width: 2px;
  outline-style: solid;
}

.info-green {
  align-items: center;
  display: flex;
  background-color: #CDE990;
  box-shadow: inset 0 0 1.6vmin 0vmin var(--dark-grey);
  border-radius: 4px;
  cursor: default;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  top: -48px;
  left: -26px;
  width: 248px;
  height: 34px;
}

.info-green:active {
  position: absolute !important;
  top: 0 !important;
}

.info-green p {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 700;
}

.info-red {
  align-items: center;
  display: flex;
  background-color: #F45050;
  box-shadow: inset 0 0 1.6vmin 0vmin var(--dark-grey);
  border-radius: .1em;
  cursor: default;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  top: -48px;
  left: -30px;
  width: 248px;
  height: 34px;
}

.info-red p {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 700;
}

.form-container button {
  background-color: var(--silver);
  border: none;
  border-radius: .6em;
  color: #343434;
  cursor: pointer;
  display: block;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .4px;
  text-transform: uppercase;
  margin: 0 auto;
  margin-top: 2.8px;
  position: relative;
  width: 188px;
  height: 28px;
}

.form-container button:focus, .form-container button:disabled {
  outline: none;
  background: #aaa;
}

.form-container button:hover {
  background-color: #bacddbc6;
}

.form-container button:focus {
  outline-style: none;
}

.spin {
  display: block;
}

.spin .spinner{
  top: 4px;
  width: 26px;
  display: block;
  position: absolute;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin .spinner::before {
  animation: .8s linear infinite spinner;
  border-radius: 50%;
  border: solid .25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 1.2em;
  height: 1.2em;
}

*, *::before, *::after {
  box-sizing: border-box;
}
