.submit-form-container {
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  padding-left: 1em;
  padding-right: 1em;
  min-width: 90vmin;
}

.submit-form-container a,
.submit-form-container p,
.submit-form-container h1,
.submit-form-container h2,
.submit-form-container h3 {
  color: var(--snow-drift);
  font-weight: 500;
}

.submit-form-container li {
  color: var(--snow-drift);
  font-weight: 300;
}

/* ToS */
.tos-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: 1vmin;
  margin-top: 2rem;
  width: 80vmin;
}

.tos-first-section h1 {
  font-size: 4vmin;
  font-weight: 500;
}

.tos-first-section h2 {
  font-size: 3vmin;
  font-weight: 500;
}

.tos-second-section h3 {
  font-size: 1.7vmin;
  font-weight: 500;
}

.tos-second-section li {
  font-size: 1.6vmin;
  margin-bottom: 0.6vmin;
}

.tos-second-section li:hover {
  color: #d2d2d2;
}

/* Form */
.form-container {
  align-items: center;
  border-radius: 1em;
  box-shadow: 0px 0px 6em 1em #bacddb11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1vmin;
  margin-top: 10vmin;
  width: 70vmin;
  height: auto;

  /* Background */
  opacity: 1;
  background-color: #161616;
  background-image: radial-gradient(#c4d86d2b 0.75px, transparent 0.75px), radial-gradient(#c4d86d2b 0.75px, #161616 0.75px);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}

.form-container {
  padding: 2vmin;
}

.form-container label {
  color: var(--foggy-grey);
  font-size: 3vmin;
  font-weight: 500;
  letter-spacing: .2vmin;
}

.form-container textarea {
  border: none;
  border-radius: 1em;
  background-color: #343434;
  color: var(--foggy-grey);
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 2.4vmin;
  padding-left: 1vmin;
  width: 18em;
  height: 10em;
  min-width: 18vmin;
  max-width: 62vmin;
  min-height: 4vmin;
  max-height: 32vmin;
  float: left;
  margin-top: 0.4em;
}

.form-container ::-webkit-scrollbar {
  width: 1.3vmin !important;
}

.form-container ::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: #5d5d5d !important;
}

.form-container textarea:focus {
  outline-color: #BACDDB;
  outline-width: 0.2em;
  outline-style: solid;
}

.form-container h4 {
  font-size: 1.8vmin;
  font-weight: 600;
  margin-top: 3.6vmin;
  margin-bottom: 1vmin;
}

.form-container li {
  text-align: start;
  font-size: 1.6vmin;
  margin-bottom: .4vmin;
  width: 100%;
}

.form-container p {
  text-align: start;
  font-size: 1.6vmin;
  font-weight: 400;
  margin-bottom: .4vmin;
  margin-top: .6vmin;
  width: 100%;
}

.atc-download-btn {
  font-size: 1.6vmin !important;
  font-weight: 600 !important;
  margin-top: 0em !important;
  margin-bottom: 1vmin !important;
  padding: .0em .0em !important;
  width: 28vmin !important;
  height: 2em !important;
}

.atc-extension-container {
  align-items: center;
  background-color: #1616163d;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.atc-tos-section {
  align-items: center;
  background-color: #161616;
  border-radius: 2vmin;
  display: flex;
  flex-direction: column;
  padding-top: 1.8vmin;
  padding-bottom: 1.8vmin;
  width: 70vw;
  max-height: 94vh;
}

.atc-tos-first-section {
  margin-left: 2vmin;
  margin-right: 2vmin;
}

.atc-tos-first-section h1 {
  font-size: 4vmin;
  font-weight: 500;
  text-align: center;
}

.atc-tos-first-section h2 {
  font-size: 3vmin;
  font-weight: 500;
  text-align: center;
}

.atc-tos-second-section h3 {
  font-size: 1.7vmin;
  font-weight: 500;
}

.atc-tos-second-section {
  overflow-y: scroll;
  margin-top: .2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  height: 72%;
}

.atc-tos-second-section li {
  font-size: 1.6vmin;
  margin-bottom: 0.6vmin;
}

.atc-tos-agree-btn {
  font-size: 2.4vmin !important;
  font-weight: 700 !important;
  margin: 0 auto !important;
  margin-top: 0.4em !important;
  padding: .6em .8em !important;
  width: 37vmin !important;
  height: 2.3em !important;
}

.atc-tos-cancel-btn {
  background-color: transparent !important;
  border: none !important;
  border-radius: .6vmin !important;
  color: #D0383B !important;
  cursor: pointer !important;
  display: block !important;
  font-family: 'Kumbh Sans', sans-serif !important;
  font-size: 2vmin !important;
  font-weight: 400 !important;
  letter-spacing: .2vmin !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative !important;
  text-transform: uppercase !important;
  user-select: none !important;
  width: 10vmin !important;
  height: 3.8vmin !important;
  outline: none !important;
}

@media (hover: hover) and (pointer: fine) {
  .atc-tos-cancel-btn:hover {
    color: #d0383bde !important;
  }
}

.info-green {
  align-items: center;
  display: flex;
  background-color: #CDE990;
  box-shadow: inset 0 0 1.6vmin 0vmin var(--dark-grey);
  border-radius: .1em;
  cursor: default;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  top: -2.6em;
  left: .2em;
  width: 36vmin;
  height: 2em;
}

.info-green:active {
  position: absolute !important;
  top: 0 !important;
}

.info-green p {
  color: var(--dark-grey);
  font-size: 1.6vmin;
  font-weight: 700;
}

.info-red {
  align-items: center;
  display: flex;
  background-color: #F45050;
  box-shadow: inset 0 0 1.6vmin 0vmin var(--dark-grey);
  border-radius: .1em;
  cursor: default;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  top: -2.6em;
  left: .2em;
  width: 36vmin;
  height: 2em;
}

.info-red p {
  color: var(--dark-grey);
  font-size: 1.6vmin;
  font-weight: 700;
}

.form-container button {
  background-color: var(--silver);
  border: none;
  border-radius: .6em;
  color: #343434;
  cursor: pointer;
  display: block;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 2.4vmin;
  font-weight: 700;
  letter-spacing: .02em;
  text-transform: uppercase;
  margin: 0 auto;
  margin-top: 0.4em;
  padding: .6em .8em;
  position: relative;
  transition: all 1s;
  width: 37vmin;
  height: 2.3em;
}

.form-container button:focus, .form-container button:disabled {
  outline: none;
  background: #aaa;
}

.form-container button:hover {
  background-color: #bacddbc6;
}

.form-container button:focus {
  outline-style: none;
}

.form-container a {
  margin-top: .4em;
  font-size: 1.6vmin;
}

.form-container a:hover {
  color: #999;
}

.spin {
  padding-left: 2.5em;
  display: block;
}

.spin .spinner{
  left: -.6em;
  top: .4em;
  width: 2.5em;
  display: block;
  position: absolute;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin .spinner::before {
  animation: .8s linear infinite spinner;
  border-radius: 50%;
  border: solid .25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 1.5em;
  height: 1.5em;
}

*, *::before, *::after {
  box-sizing: border-box;
}