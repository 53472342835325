.channel-tab-list {
  background-color: #282828;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-left: 90px;
  overflow-y: auto;
  width: 240px;
  height: 100%;

  /* Background */
  background-color: #161616;
  opacity: 1;
  background-image: radial-gradient(#bacddb11 0.75px, transparent 0.75px), radial-gradient(#bacddb11 0.75px, #181818 0.75px);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}

.channel-tab-top {
  align-items: center;
  background-color: #1c1c1c;
  border: none;
  border-bottom: solid;
  border-width: 0px;
  border-color: var(--dark-grey);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 0px 0px 0px rgba(122, 112, 112, 0),
  0px 10px 12px -10px rgba(186, 205, 219, 0.1),
  0px -10px 10px -10px rgba(0, 0, 0, 0);
  display: flex;
  position: fixed;
  width: 240px;
  min-height: 50px;
  max-height: 74px;
  z-index: 2;
}

.channel-tab-title {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding-left: 10px;
  overflow-wrap: break-word;
  width: 100%;
}

.channel-tab-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 62px;
}

.region {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
}

.region-title {
  align-items: center;
  /* background-color: #1f1f1f; */
  border-radius: 20px;
  color: #5C5F62;
  cursor: pointer;
  display: flex;
  margin: 0;
  padding-left: 0px;
  text-transform: uppercase;
  height: 24px;
}

.region-title:hover {
  color: var(--foggy-grey);
}

.region-title:active {
  color: #ffffff;
}

.region-title-arrow-img {
  width: 20px;
  transform: rotate(90deg);
}

/* .region-title:hover .region-title-arrow-right-img {
  content: url(../assets/arrow-right-hover.svg);
  filter: invert(87%) sepia(22%) saturate(195%) hue-rotate(164deg) brightness(91%) contrast(88%);
}

.region-title:active .region-title-arrow-right-img {
  content: url(../assets/arrow-right-active.svg);
  filter: invert(91%) sepia(92%) saturate(29%) hue-rotate(252deg) brightness(107%) contrast(100%);
} */

.region-title-arrow-down-img {
  transform: rotate(0deg);
}

/* .region-title:hover .region-title-arrow-down-img {
  content: url(../assets/arrow-right-hover.svg);
  filter: invert(87%) sepia(22%) saturate(195%) hue-rotate(164deg) brightness(91%) contrast(88%);
}

.region-title:active .region-title-arrow-down-img {
  content: url(../assets/arrow-right-active.svg);
  filter: invert(91%) sepia(92%) saturate(29%) hue-rotate(252deg) brightness(107%) contrast(100%);
} */

.channel {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 10px;
  margin-top: 6px;
  margin-bottom: 6px;
  height: 20px;
}

.channel-hide {
  display: none;
}

.channel-navlink {
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  color: var(--foggy-grey);
  display: flex;
  font-size: 15px;
  font-weight: 400;
  padding-left: 4px;
  margin-right: 2px;
  height: 100%;
  width: 90%;
}

.channel:hover .channel-navlink {
  color: var(--foggy-grey);
  background-color: #484848;
}

.channel:active .channel-navlink {
  color: var(--foggy-grey);
  background-color: #5a5a5a;
}

.channel-navlink:active {
  color: var(--foggy-grey);
}

.channel-hashtag-img {
  width: 18px;
}

.channel-delete-button {
  height: 20px;
  min-height: 20px;
  width: 20px;
  min-width: 20px;
  z-index: 2;
}

.channel-delete-button {
  display: none;
  z-index: 0;
}

.channel:hover .channel-delete-button {
  align-items: center;
  background-color: #3b3b3b;
  border-radius: 100%;
  color: var(--foggy-grey);
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.channel:hover .channel-delete-button:hover {
  background-color: #2e2e2e;
}

.channel-delete-img {
  width: 188%;
}

.channel-add {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.channel-add-form {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  padding-left: 0px;
  padding-right: 0px;
  height: auto;
  width: 100%;
  z-index: 0;
}

/* delete */
/* .channel-add-form input {
  color: var(--foggy-grey);
  background-color: #1d1d1d;
  border: none;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 300;
  padding-left: 2px;
  padding-right: 20px;
  position: absolute;
  height: 20px;
  width: 100%;
  z-index: 0;
}

.channel-add-form input:hover {
  background-color: #2b2b2b;
}

.channel-add-form input:focus {
  outline-color: #5f5f5f;
  outline-style: solid;
  outline-width: 0.6px;
}

.channel-add-form input::placeholder {
  font-weight: 400;
}

.channel-button-section {
  display: flex;
  height: 20px;
  z-index: 1;
}

.channel-add-form button {
  align-items: center;
  background-color: #3b3b3b;
  border-radius: 4px;
  color: var(--foggy-grey);
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 20px;
  min-height: 20px;
  width: 20px;
  min-width: 20px;
  z-index: 1;
}

.channel-add-form button:hover {
  background-color: #2e2e2e;
}

.channel-add-img {
  width: 238%;
} */
/* delete */

.channel-menu-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.channel-menu-container-hide {
  display: none;
}

.channel-menu-container button {
  align-items: center;
  background-color: #1d1d1d;
  border-radius: 2  px;
  color: var(--foggy-grey);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: center;
  width: 100%;
  height: 20px;
  min-height: 20px;
}

.channel-menu-container button:hover {
  background-color: #2b2b2b;
}

.channel-menu-container button:active {
  background-color: #323232;
}

.channel-option-container {
  background-color: #1d1d1d;
  display: none;
  flex-direction: column;
  margin-top: 4px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  height: 100%;
  width: 100%;
}

.channel-option-container-show {
  display: flex;
}

.channel-option-container p {
  align-items: center;
  background-color: #1f1f1f;
  border-radius: 2px;
  color: var(--foggy-grey);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 4px;
}

.channel-option-container p:hover {
  background-color: #2b2b2b;
}

.channel-option-container p:active {
  background-color: #323232;
}