.skus {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 52px;
}

.spin {
  display: block;
}

.spin .spinner{
  top: 4px;
  width: 26px;
  display: block;
  position: absolute;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin .spinner::before {
  animation: .8s linear infinite spinner;
  border-radius: 50%;
  border: solid .25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 1.2em;
  height: 1.2em;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.search-sku-imgs {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.sku-search-title {
  color: var(--foggy-grey);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.6px;
  margin: 0;
}

.search-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 30px;
}

.sku-search-input {
  background-color: #343434;
  border: none;
  border-radius: 6px;
  color: var(--foggy-grey);
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 18px;
  font-weight: 300;
  padding-right: 50px;
  height: 32px;
  width: 272px;
}

.sku-search-input:focus {
  outline-color: #5f5f5f;
  outline-style: solid;
  outline-width: .2px;
}

.sku-search-input::placeholder {
  font-size: 18px;
  font-family: 300;
}

.search-img {
  cursor: pointer;
  margin-right: -238px;
  transition: filter .2s;
  position: absolute;
  width: 26px;
  z-index: 1;
}

.search-img:hover {
  filter: invert(87%) sepia(1%) saturate(441%) hue-rotate(18deg) brightness(97%) contrast(82%);
}

.sku-image-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 6px;
  column-gap: 6px;
  margin-top: 10px;
  margin-bottom: 44px;
}

@media (max-width: 374px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 326px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 660px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1022px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1440px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1840px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.sku-image-box {
  display: flex;
  flex-direction: column;
}

.sku-img {
  width: 166px;
}

.download-img {
  cursor: pointer;
  margin-top: 4px;
  margin-left: 4px;
  position: absolute;
  width: 18px;
}

.download-img:hover  {
  filter: invert(87%) sepia(1%) saturate(441%) hue-rotate(18deg) brightness(97%) contrast(82%);
}

.second-download-img {
  cursor: pointer;
  margin-top: 4px;
  margin-left: 28px;
  position: absolute;
  width: 18px;
  filter: invert(397%) sepia(10%) saturate(441%) hue-rotate(180deg) brightness(97%) contrast(82%);
}

.second-download-img:hover  {
  filter: invert(87%) sepia(1%) saturate(441%) hue-rotate(18deg) brightness(97%) contrast(82%);
}

.download-all-btn {
  color: var(--silver);
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: .8px;
  margin: 0;
  margin-top: -22px;
}

@media (hover: hover) and (pointer: fine) {
  .download-all-btn:hover {
    color: var(--silver-hover);
  }
}

.download-all-btn:focus {
  color: #cad5dcaf;
}

.search-spin {
  display: block;
}

.search-spin .search-spinner{
  display: block;
  position: relative;
  top: 2px;
  left: -16px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.search-spin .search-spinner::before {
  animation: .8s linear infinite spinner;
  border-radius: 50%;
  border: solid .25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 2.2em;
  height: 2.2em;
}









.image-editor-container {
  align-items: center;
  /* background-color: rgba(91, 82, 82, 0.5); */
  display: flex;
  justify-content: center;
  position: fixed;
  margin: auto;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
}

.image-editor {
  align-self: center;
  background-color: #34343471;
  backdrop-filter: blur(19px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
}

@media (min-width: 1px) {
  .wrapper {
    margin: auto;
  }

  .cropped-img {
    margin: auto;
  }

  .image-editor {
    flex-direction: column;
  }
}

@media (min-width: 720px) {
  .image-editor {
    flex-direction: row;
  }
}

.wrapper {
  width: 500px;
  height: auto;
  max-width: 500px;
  max-height: 500px;
  min-width: 10%;
  min-height: 10%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.wrapper::after {
  display: block;
  content: "";
  padding-bottom: 100%;
}

.cropped-img {
  width: 100%;
  height: 100%;
  min-width: 10%;
  min-height: 10%;
  max-width: 500px;
  max-height: 500px;
  object-fit: contain;
}

.input-box {
  align-items: center;
  background-color: var(--dark-grey);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -10%);
  width: 210px;
  height: 190px;
}

.input-aspect-ratio {
  align-items: center;
  display: flex;
  justify-content: center;
  color: var(--snow-drift);
  font-weight: 500;
  font-size: 18px;
}

.input-number {
  background-color: #414141;
  border: none;
  border-radius: 2px;
  color: var(--foggy-grey);
  font-family: 'Kumbh Sans', sans-serif;
  outline-color: #5f5f5f;
  outline-style: solid;
  outline-width: .2px;
}

.sku-name {
  background-color: #414141;
  border: none;
  border-radius: 2px;
  color: var(--foggy-grey);
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  padding-right: 2px;
  outline-color: #5f5f5f;
  outline-style: solid;
  outline-width: .2px;
  margin-top: 4px;
  margin-bottom: 4px;
  height: 22px;
  width: 142px;
}

.sku-name::placeholder {
  font-size: 18px;
  font-family: 300;
}

.download-btn {
  color: var(--silver);
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: .8px;
  margin: 0;
}

@media (hover: hover) and (pointer: fine) {
  .download-btn:hover {
    color: var(--silver-hover);
  }
}

.download-btn:focus {
  color: #cad5dcaf;
}

.close-btn {
  color: #D0383B;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: .8px;
  user-select: none;
  margin: 0;
}

@media (hover: hover) and (pointer: fine) {
  .close-btn:hover {
    color: #d0383bde;
  }
}








.bottom-container {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  height: 72px;
  min-height: 72px;
  width: 100%;
  animation-name: in;
  animation-duration: .2s;
  z-index: 2;
}

@keyframes in {
  0% {bottom: -100px;}
  100% {bottom: 0;}
}

.menu-box {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: scroll !important;
  height: 58px;
  width: 100%;
}

.menu-btn {
  align-items: center;
  background-color: transparent;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 24px;
  margin-bottom: 20px;
  position: absolute;
  left: 0;
  height: 52px;
  width: 52px;
  z-index: 5;
}

@media (min-width: 1024px) {
  .menu-btn {
    margin-left: 120px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .menu-btn:hover {
    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(358deg) brightness(108%) contrast(101%);
  }
}

.menu-dots-img {
  border-radius: 100%;
  pointer-events: none;
  user-select: none;
  width: 58px;
}

.parameter-container {
  align-items: center;
  background-color: #1e1e1ea3;
  backdrop-filter: blur(8px);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 -20px 400px 10px rgb(0, 0, 0, .4);
  display: none;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: -620px;
  height: auto;
  width: 100%;
  transition: bottom .4s;
  z-index: 6;
}

@media (min-width: 1024px) {
  .parameter-container {
    background-color: #1e1e1e;
    backdrop-filter: none;
    box-shadow: none;
    margin-left: auto;
    margin-right: auto;
    width: 660px;
  }
}

.parameter-background {
  background-color: #c96767;
  height: 300px;
  width: 100%;
  z-index: 20;
  user-select: none;
  pointer-events: none;
}

.parameter-container-show {
  animation-name: parameter-container-in;
  animation-duration: .4s;
  bottom: 0;
  display: flex;
}

@keyframes parameter-container-in {
  0% {bottom: -620px;}
  100% {bottom: 0;}
}

.parameter-form-title {
  color: var(--foggy-grey);
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 1.6px;
  margin-top: 22px;
  margin-bottom: 0;
  text-align: center;
  user-select: none;
  width: 100%;
}

.image-option-box {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.image-option-box div {
  width: 100px;
}

@media (max-width: 428px) {
  .image-option-box {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1022px) {
  .image-option-box {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.image-option-box label {
  color: var(--foggy-grey);
  padding-left: 6px;
}

.image-option-box input {
  color: var(--foggy-grey);
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 16px;
  padding-left: 6px;
  background-color: #343434;
  border: none;
  border-radius: 8px;
  width: 92px;
}

.image-option-box input::-webkit-outer-spin-button,
.image-option-box input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.image-option-box input::placeholder {
  color: var(--foggy-grey);
}

.image-option-box input:focus {
  outline: solid;
  outline-width: .4px;
  outline-color: var(--silver);
}

.parameter-color {
  margin-right: 6px;
}

.parameter-color[type="color" i] {
  appearance: auto;
  inline-size: 100px;
  block-size: 28px;
  cursor: pointer;
  box-sizing: none;
  background-color: transparent;
  color: none;
  border-width: 0;
  border-style: hidden;
  border-color: transparent;
  border-image: none;
  padding: 0;
  margin-top: 6px;
}

.parameter-checkbox-box .parameter-checkbox {
  appearance: none;
  background-color: var(--foggy-grey);
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  margin-left: 6px;
  position: relative;
  width: 38px;
}

.parameter-checkbox-box .parameter-checkbox::before {
  cursor: pointer;
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.parameter-checkbox-box .parameter-checkbox,
.parameter-checkbox-box .parameter-checkbox::after {
  transition: all 100ms ease-out;
}

.parameter-checkbox-box .parameter-checkbox::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  cursor: pointer;
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.parameter-checkbox-box input[type=checkbox] {
  cursor: default;
}

.parameter-checkbox-box .parameter-checkbox:hover {
  background-color: var(--silver-hover);
  transition-duration: 0s;
}

.parameter-checkbox-box .parameter-checkbox:checked {
  background-color: var(--silver);
}

.parameter-checkbox-box .parameter-checkbox:checked::after {
  background-color: #fff;
  left: 20px;
}

.parameter-checkbox-box :focus:not(.focus-visible) {
  outline: 0;
}

.parameter-checkbox-box .parameter-checkbox:checked:hover {
  background-color: var(--silver-hover);
}

.reset-btn {
  background-color: transparent;
  border: none;
  border-radius: .6em;
  color: var(--silver);
  cursor: pointer;
  display: block;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .4px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-transform: uppercase;
  user-select: none;
  width: 100px;
  height: 20px;
  outline: none;
}

@media (hover: hover) and (pointer: fine) {
  .reset-btn:hover {
    color: var(--silver-hover);
  }
}

.cancel-btn {
  background-color: transparent;
  border: none;
  border-radius: .6em;
  color: #D0383B;
  cursor: pointer;
  display: block;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .4px;
  margin-top: 4px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-transform: uppercase;
  user-select: none;
  width: 100px;
  height: 20px;
  outline: none;
}

@media (hover: hover) and (pointer: fine) {
  .cancel-btn:hover {
    color: #d0383bde;
  }
}












.first-background {
  cursor: pointer;
  position: absolute;
  top: 140px;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.second-background {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}












.wrapper {
  width: 100%;
  max-width: 500px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.wrapper::after {
  display: block;
  content: "";
  padding-bottom: 100%;
}
