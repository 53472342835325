/* Middle Section */
.middle-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 440px;
  padding-top: 100px;
  scroll-margin-top: 100px;
  width: 90%;
  height: 900px;
}

.middle-section-title-container {
  display: flex;
  flex-direction: column;
}

.middle-section-title {
  font-size: 40px;
  text-align: center;
  margin: 0;
  width: 100%;
}

.middle-section-title span {
  color: var(--silver);
  letter-spacing: .10em;
}

.middle-section-title span:nth-child(2) {
  color: #5865F2;
  letter-spacing: .05em;
}

.middle-section-subtitle {
  color: rgba(202, 202, 202, 0.5);
  font-size: 20px;
  text-align: center;
  margin: 0;
  width: 100%;
}

.middle-section-container {
  display: flex;
  margin-top: 200px;
}

.card {
  align-items: center;
  display: flex;
  background-color: var(--silver);
  border-radius: 12px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  flex-direction: column;
  margin-right: 0%;
  margin-left: 1%;
  position: relative;
  width: 34vmin;
  height: 28vmin;
  transition: background-color .2s;
}

.card:hover {
  background-color: #bacddb97;
  top: -2px;
}

.card h3 {
  align-items: center;
  background-color: #343434;
  border-radius: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 10px 13px 1px rgba(0, 0, 0, 0.41);
  color: var(--silver);
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  margin: 0;
  position: relative;
  top: -6px;
  width: 100%;
  height: 16%;
}

.card p {
  color: var(--dark-grey);
  font-size: 18px;
  font-weight: 500;
  width: 28vmin;
}

.middle-section .extra-text {
  border-color: rgba(221, 221, 221, 0.103);
  border-width: 1px;
  border-style: solid;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  color: var(--snow-drift);
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  top: 2160px;
  width: 90%;
}