/* Navigation */
.navigation {
  align-items: center;
  background-color: #161616ad;
  backdrop-filter: blur(4px);
  box-shadow: 0px 10px 60px 22px #bacddb11;
  display: flex;
  justify-content: space-around;
  position: fixed;
  padding: 0 0 0 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  z-index: 1;
}

.left-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 440px;
}

.logo {
  -webkit-user-drag: none;
  pointer-events: none;
  width: 4.6em;
}

.right-header {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 238px;
}

.home-link:hover,
.features-link:hover,
.faq-link:hover,
.user-portal-link:hover {
  position: relative;
  top: 1px;
}

.home-link:active,
.features-link:active,
.faq-link:active,
.user-portal-link:active {
  top: 1.2px;
}

.dashboard-btn {
  color: var(--dark-grey);
  cursor: pointer;
  background-color: var(--silver);
  border-radius: 30px;
  box-shadow: 0px 1px 13px 1px rgba(0, 0, 0, 0.41);
  font-size: 20px;
  font-weight: 700;
  width: 220px;
  height: 44px;
  transition: background-color .2s, color .2s;
}

.dashboard-btn:hover {
  color: var(--dark-grey);
  background-color: var(--silver-hover);
}

.dashboard-btn:active {
  color: var(--silver);
  background-color: #343434;
  border-style: solid;
  border-width: 2px;
  border-color: var(--silver);
}