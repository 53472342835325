/* Top Section */
.top-section {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  scroll-margin-top: 22px;
  width: 100%;
  height: 900px;
}

.example-video {
  border-radius: 30px;
  box-shadow: 0px 0px 13px 10px #bacddb11;
  margin-right: 120px;
  -webkit-user-drag: none;
  pointer-events: none;
  width: 400px;
}

.top-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-section-title {
  font-size: 60px;
  margin: 0;
  width: 480px;
}

.top-section-title span {
  color: var(--silver);
  letter-spacing: .10em;
}

.top-section p {
  color: #fcfaf161;
  font-size: 18px;
  margin: 0;
  width: 500px;
}

.discord-button {
  align-items: center;
  color: var(--dark-grey);
  cursor: pointer;
  background-color: var(--silver);
  border-radius: 30px;
  box-shadow: 0px 1px 13px 1px rgba(0, 0, 0, 0.41);
  display: flex;
  font-size: 26px;
  font-weight: 700;
  justify-content: center;
  margin-top: 20px;
  width: 380px;
  height: 68px;
  transition: background-color .2s, color .2s;
}

.discord-button:hover {
  color: var(--dark-grey);
  background-color: var(--silver-hover);
}

.discord-button:hover {
  color: var(--silver);
  background-color: #34343466;
  border-style: solid;
  border-width: 2px;
  border-color: var(--silver);
}

.discord-icon {
  -webkit-user-drag: none;
  margin-right: 20px;
  width: 2.4em;
}

.second-example-video {
  display: none;
  border-radius: 30px;
  box-shadow: 0px 0px 13px 10px #bacddb11;
  margin-right: 0px;
  -webkit-user-drag: none;
  pointer-events: none;
  position: absolute;
  top: 740px;
  width: 38vmax;
  max-width: 120vmin;
}

.top-section .extra-text {
  border-color: rgba(221, 221, 221, 0.103);
  border-width: 1px;
  border-style: solid;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  color: var(--snow-drift);
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  top: 1140px;
  width: 90%;
}