/* Navigation */
@media (max-width: 768px) {
  .navigation {
    align-items: center;
    background-color: #161616ad;
    backdrop-filter: blur(4px);
    box-shadow: 0px 10px 60px 22px #bacddb11;
    display: flex;
    justify-content: space-around;
    position: fixed;
    padding: 0 0 0 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 12vmin;
    z-index: 1;
  }

  .left-header {
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 54.6vmin;
    height: 11vmin;
  }

  .logo {
    -webkit-user-drag: none;
    pointer-events: none;
    width: 10vmin;
  }

  .right-header {
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 30vmin;
    height: 11vmin;
  }

  .home-link,
  .features-link,
  .faq-link,
  .user-portal-link {
    font-size: 2vmin;
    margin-left: 1.6vmin;
  }

  .home-link:hover,
  .features-link:hover,
  .faq-link:hover,
  .user-portal-link:hover {
    position: relative;
    top: 1px;
  }

  .home-link:active,
  .features-link:active,
  .faq-link:active,
  .user-portal-link:active {
    top: 1.2px;
  }

  .dashboard-btn {
    align-items: center;
    background-color: var(--silver);
    border-radius: 30px;
    box-shadow: 0px 1px 13px 1px rgba(0, 0, 0, 0.41);
    color: var(--dark-grey);
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 2.6vmin;
    font-weight: 700;
    width: 14em;
    max-width: 26vmin;
    height: 2em;
    transition: background-color .2s, color .2s;
  }

  .dashboard-btn:hover {
    color: var(--dark-grey);
    background-color: var(--silver-hover);
  }

  .dashboard-btn:active {
    color: var(--silver);
    background-color: #343434;
    border-style: solid;
    border-width: 2px;
    border-color: var(--silver);
  }
}