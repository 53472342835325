/* Footer Section */
.footer {
  align-items: center;
  display: flex;
  background-color: #161616;
  box-shadow: 0px 10px 60px 22px #bacddb11;
  margin-top: 98px;
  padding: 20px 0 40px 0;
  flex-direction: column;
  justify-content: center;
  left: 0;
  width: 100%;
  height: auto;
}

.footer h1 {
  margin: 0;
}

.footer h1 span {
  color: var(--silver);
  letter-spacing: .10em;
}

.footer p {
  color: var(--foggy-grey);
  margin: 0;
}

/* .footer-mail {
  color: #d7af36;
}

.footer-mail:hover {
  color: #f0c851;
} */

.social-links-container {
  display: flex;
  margin-top: 20px;
}

.social-links-container a {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
}

.social-links-container img {
  -webkit-user-drag: none;
  width: 25px;
}

.social-links-container img:hover {
  position: relative;
  top: 1px;
}