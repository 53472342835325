/* Top Section */
@media (max-width: 425px) {
  .top-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 60px;
    scroll-margin-top: 22px;
    width: 96%;
    height: 500px;
  }

  .example-video {
    display: none;
    border-radius: 30px;
    box-shadow: 0px 0px 13px 10px #bacddb11;
    margin-right: 12px;
    -webkit-user-drag: none;
    pointer-events: none;
    width: 34vmax;
    max-width: 45vmin;
  }

  .top-section-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
  }

  .top-section-title {
    font-size: 8vmin;
    text-align: center;
    margin: 0;
    width: 100%;
  }

  .top-section-title span {
    color: var(--silver);
    letter-spacing: .10em;
  }

  .top-section p {
    color: #fcfaf161;
    font-size: 2.4vmin;
    text-align: center;
    margin: 0;
    width: 100%;
  }
  
  .discord-button {
    align-items: center;
    color: var(--dark-grey);
    cursor: pointer;
    background-color: var(--silver);
    border-radius: 30px;
    box-shadow: 0px 1px 13px 1px rgba(0, 0, 0, 0.41);
    display: flex;
    font-size: 3.9vmin;
    font-weight: 700;
    justify-content: center;
    margin-top: 20px;
    width: 380px;
    max-width: 51vmin;
    height: 68px;
    min-height: 11vmin;
    max-height: 8vmin;
    transition: background-color .2s, color .2s;
  }

  .discord-button:hover {
    color: var(--silver);
    background-color: #34343466;
    border-style: solid;
    border-width: 2px;
    border-color: var(--silver);
  }

  .discord-icon {
    -webkit-user-drag: none;
    margin-right: 10px;
    width: 7vmin;
  }

  .second-example-video {
    display: block;
    border-radius: 30px;
    box-shadow: 0px 0px 13px 10px #bacddb11;
    margin-right: 0px;
    -webkit-user-drag: none;
    pointer-events: none;
    position: absolute;
    top: 740px;
    width: 33vmax;
    max-width: 86vmin;
  }

  .top-section .extra-text {
    border-color: rgba(221, 221, 221, 0.103);
    border-width: 1px;
    border-style: none;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    color: var(--snow-drift);
    display: flex;
    flex-direction: column;
    font-size: 3vmin;
    position: absolute;
    text-align: center;
    top: 680px;
    width: 80%;
  }
}