.product-query {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 52px;
  margin-bottom: 52px;
  width: 100%;
}

.sku-search-title {
  color: var(--foggy-grey);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.6px;
  margin: 0;
}

.search-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 30px;
}

.sku-search-input {
  background-color: #343434;
  border: none;
  border-radius: 6px;
  color: var(--foggy-grey);
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 18px;
  font-weight: 300;
  padding-right: 50px;
  height: 32px;
  width: 272px;
}

.sku-search-input:focus {
  outline-color: #5f5f5f;
  outline-style: solid;
  outline-width: .2px;
}

.sku-search-input::placeholder {
  font-size: 18px;
  font-family: 300;
}

.search-img {
  cursor: pointer;
  margin-right: -238px;
  transition: filter .2s;
  position: absolute;
  width: 26px;
  z-index: 1;
}

@media (max-width: 262px) {
  .search-container {
    width: 1rem;
  }

  .sku-search-input {
    padding-right: 0;
    width: 10rem;
  }

  .search-img {
    margin-right: -8rem;
    width: 2rem;
  }
}

.search-img:hover {
  filter: invert(87%) sepia(1%) saturate(441%) hue-rotate(18deg) brightness(97%) contrast(82%);
}

.search-spin {
  display: block;
}

.search-spin .search-spinner{
  display: block;
  position: relative;
  top: 2px;
  left: -16px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.search-spin .search-spinner::before {
  animation: .8s linear infinite spinner;
  border-radius: 50%;
  border: solid .25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 2.2em;
  height: 2.2em;
}

@font-face {
  font-family: 'Whitney';
  src: url('../assets/fonts/whitney-400.woff2') format('woff2'),
       url('../assets/fonts/whitney-500.woff2') format('woff2'),
       url('../assets/fonts/whitney-600.woff2') format('woff2'),
       url('../assets/fonts/whitney-700.woff2') format('woff2');
  font-weight: 400, 500, 600, 700;
}

.product-section {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 4px;
}

@media (max-width: 1520px) {
  .product-section {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1216px) {
  .product-section {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 828px) {
  .product-section {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 402px) {
  .product-section {
    overflow-x: scroll;
    width: 100%;
  }
}

.product-container {
  background: #bacddb11;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  margin-top: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  height: auto;
  width: 400px;
}

.product-data {
  user-select: text;
}

.product-data p {
  color: var(--snow-drift);
  font-size: 20px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 8px;
}

.product-data span {
  color: var(--foggy-grey);
  font-weight: 400;
}

.product-region,
.product-title,
.product-sku,
.product-subtitle,
.product-commercestartdate,
.product-price,
.product-discounted,
.product-promo,
.product-colordescription,
.product-slug,
.product-descriptionheading,
.product-description {
  background-color: #4d474755;
  border-radius: 8px;
  padding-left: 4px;
  padding-right: 4px;
}

.product-region:hover,
.product-title:hover,
.product-sku:hover,
.product-subtitle:hover,
.product-commercestartdate:hover,
.product-price:hover,
.product-discounted:hover,
.product-promo:hover,
.product-colordescription:hover,
.product-slug:hover,
.product-descriptionheading:hover,
.product-description:hover {
  background-color: #4d47479a;
}

.product-title {

}

.product-sku {

}

.product-subtitle {

}

.product-commercestartdate {

}

.product-price {

}

.product-discounted {

}

.product-promo {

}

.product-colordescription {

}

.product-slug {

}

.product-descriptionheading {

}

.product-description {

}

.product-images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4px;
}

.product-image-box {
  display: flex;
  flex-direction: column;
}

.product-preview-image {
  border-radius: 6px;
  height: 100%;
  width: 100%;
}

.download-img {
  cursor: pointer;
  margin-top: 4px;
  margin-left: 4px;
  position: absolute;
  width: 18px;
}

.download-img:hover  {
  filter: invert(87%) sepia(1%) saturate(441%) hue-rotate(18deg) brightness(97%) contrast(82%);
}

@media (max-width: 828px) {
  .product-images {
    grid-template-columns: 1fr 1fr;
  }

  .download-img {
    position: relative;
  }
}
