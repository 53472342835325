.skus {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 52px;
}

.form-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 22px;
}

.form-container label {
  color: var(--foggy-grey);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.6px;
}





.form-container input {
  background-color: #343434;
  border: none;
  border-radius: 6px;
  color: var(--foggy-grey);
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 18px;
  font-weight: 300;
  padding-right: 50px;
  height: 32px;
  width: 272px;
}

.form-container input:focus {
  outline-color: #5f5f5f;
  outline-style: solid;
  outline-width: .2px;
}

.form-container input::placeholder {
  font-size: 18px;
  font-family: 300;
}




.form-container textarea {
  border: none;
  border-radius: 8px;
  background-color: #343434;
  color: var(--foggy-grey);
  float: left;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 16px;
  padding-left: 6px;
  min-width: 180px;
  max-width: 480px;
  min-height: 88px;
  max-height: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 300px;
  height: 140px;
}

.form-container ::-webkit-scrollbar {
  width: 8px !important;
}

.form-container ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #5d5d5d !important;
}

.form-container textarea:focus {
  outline-color: #BACDDB;
  outline-width: 2px;
  outline-style: solid;
}

.info-green {
  align-items: center;
  display: flex;
  background-color: #CDE990;
  box-shadow: inset 0 0 1.6vmin 0vmin var(--dark-grey);
  border-radius: 4px;
  cursor: default;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  top: -48px;
  left: -26px;
  width: 248px;
  height: 34px;
}

.info-green:active {
  position: absolute !important;
  top: 0 !important;
}

.info-green p {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 700;
}

.info-red {
  align-items: center;
  display: flex;
  background-color: #F45050;
  box-shadow: inset 0 0 1.6vmin 0vmin var(--dark-grey);
  border-radius: .1em;
  cursor: default;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  top: -48px;
  left: -30px;
  width: 248px;
  height: 34px;
}

.info-red p {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 700;
}

.form-container button {
  background-color: var(--silver);
  border: none;
  border-radius: .6em;
  color: #343434;
  cursor: pointer;
  display: block;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .4px;
  text-transform: uppercase;
  margin: 0 auto;
  margin-top: 2.8px;
  position: relative;
  width: 188px;
  height: 28px;
}

.form-container button:focus, .form-container button:disabled {
  outline: none;
  background: #aaa;
}

.form-container button:hover {
  background-color: #bacddbc6;
}

.form-container button:focus {
  outline-style: none;
}

.spin {
  display: block;
}

.spin .spinner{
  top: 4px;
  width: 26px;
  display: block;
  position: absolute;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin .spinner::before {
  animation: .8s linear infinite spinner;
  border-radius: 50%;
  border: solid .25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 1.2em;
  height: 1.2em;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.search-sku-imgs {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}

.sku-search-title {
  color: var(--foggy-grey);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.6px;
  margin: 0;
}

.search-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 30px;
}

.sku-search-input {
  background-color: #343434;
  border: none;
  border-radius: 6px;
  color: var(--foggy-grey);
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 18px;
  font-weight: 300;
  padding-right: 50px;
  height: 32px;
  width: 272px;
}

.sku-search-input:focus {
  outline-color: #5f5f5f;
  outline-style: solid;
  outline-width: .2px;
}

.sku-search-input::placeholder {
  font-size: 18px;
  font-family: 300;
}

.search-img {
  cursor: pointer;
  margin-right: -238px;
  transition: filter .2s;
  position: absolute;
  width: 26px;
  z-index: 1;
}

.search-img:hover {
  filter: invert(87%) sepia(1%) saturate(441%) hue-rotate(18deg) brightness(97%) contrast(82%);
}

.sku-customize-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sku-search-color {
  margin-right: 6px;
}

.sku-search-color[type="color" i] {
  appearance: auto;
  inline-size: 100px;
  block-size: 28px;
  cursor: pointer;
  box-sizing: none;
  background-color: transparent;
  color: none;
  border-width: 0;
  border-style: hidden;
  border-color: transparent;
  border-image: none;
  padding: 0;
  /* width: 28px;
  height: 28px; */
}

.sku-customize-container .sku-search-checkbox {
  appearance: none;
  background-color: var(--foggy-grey);
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  margin-left: 6px;
  position: relative;
  width: 38px;
}

.sku-customize-container .sku-search-checkbox::before {
  cursor: pointer;
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.sku-customize-container .sku-search-checkbox,
.sku-customize-container .sku-search-checkbox::after {
  transition: all 100ms ease-out;
}

.sku-customize-container .sku-search-checkbox::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  cursor: pointer;
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.sku-customize-container input[type=checkbox] {
  cursor: default;
}

.sku-customize-container .sku-search-checkbox:hover {
  background-color: var(--silver-hover);
  transition-duration: 0s;
}

.sku-customize-container .sku-search-checkbox:checked {
  background-color: var(--silver);
}

.sku-customize-container .sku-search-checkbox:checked::after {
  background-color: #fff;
  left: 20px;
}

.sku-customize-container :focus:not(.focus-visible) {
  outline: 0;
}

.sku-customize-container .sku-search-checkbox:checked:hover {
  background-color: var(--silver-hover);
}































.sku-image-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 6px;
  column-gap: 6px;
  margin-top: 10px;
  margin-bottom: 44px;
}

@media (max-width: 374px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 326px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 660px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1022px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1440px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1840px) {
  .sku-image-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.sku-image-box {
  display: flex;
  flex-direction: column;
}

.sku-img {
  width: 166px;
}

.download-img {
  cursor: pointer;
  margin-top: 4px;
  margin-left: 4px;
  position: absolute;
  width: 18px;
}

.download-img:hover  {
  filter: invert(87%) sepia(1%) saturate(441%) hue-rotate(18deg) brightness(97%) contrast(82%);
}

.search-spin {
  display: block;
}

.search-spin .search-spinner{
  display: block;
  position: relative;
  top: 2px;
  left: -16px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.search-spin .search-spinner::before {
  animation: .8s linear infinite spinner;
  border-radius: 50%;
  border: solid .25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 2.2em;
  height: 2.2em;
}