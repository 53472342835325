/* Middle Section */
@media (max-width: 425px) {
  .middle-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 660px;
    padding-top: 20px;
    scroll-margin-top: 100px;
    width: 90%;
    height: 660px;
  }

  .middle-section-title-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .middle-section-title {
    font-size: 4vmin;
    text-align: center;
    margin: 0;
    width: 83vmin;
  }

  .middle-section-title span {
    color: var(--silver);
    letter-spacing: .10em;
  }

  .middle-section-title span:nth-child(2) {
    color: #5865F2;
    letter-spacing: .05em;
  }

  .middle-section-subtitle {
    color: rgba(202, 202, 202, 0.5);
    font-size: 2.4vmin;
    text-align: center;
    margin: 0;
    width: 88%;
  }

  .middle-section-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
    column-gap: 6px;
    margin-top: 4vmin;
  }

  .card {
    align-items: center;
    display: flex;
    background-color: var(--silver);
    border-radius: 12px;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    flex-direction: column;
    margin-right: 1%;
    margin-left: 1%;
    position: relative;
    width: 42vmin;
    height: 34vmin;
    transition: background-color .2s;
  }

  .card:hover {
    background-color: #bacddb97;
    top: -2px;
  }

  .card h3 {
    align-items: center;
    background-color: #343434;
    border-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0px 10px 13px 1px rgba(0, 0, 0, 0.41);
    color: var(--silver);
    display: flex;
    font-size: 2.7vmin;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    margin: 0;
    position: relative;
    top: -1em;
    width: 100%;
    height: 2em;
  }

  .card p {
    color: var(--dark-grey);
    font-size: 2.3vmin;
    font-weight: 500;
    width: 16em;
  }

  .middle-section .extra-text {
    border-color: rgba(221, 221, 221, 0.103);
    border-width: 1px;
    border-style: solid;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    color: var(--snow-drift);
    display: flex;
    flex-direction: column;
    position: absolute;
    font-size: 3vmin;
    text-align: center;
    top: 1840px;
    width: 90%;
  }
}