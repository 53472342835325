.url-shortener {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 52px;
  user-select: text;
}

.url-shortener-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 20px;
  border: 1px solid #120d0d;
  border-radius: 16px;
  background-color: #343434;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
}

.url-shortener input {
  width: 300px;
  color: var(--snow-drift);
  font-family: "Kumbh Sans", sans-serif;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #343434;
  border: 2px solid #4e4d4d;
  border-radius: 12px;
  font-size: 20px;
}

.url-shortener input::placeholder {
  color: var(--foggy-grey);
}

.url-shortener input:focus {
  outline: solid;
  outline-width: 0.2px;
  outline-color: #5d5c5c;
}

.url-shortener button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 12px;
  background-color: var(--silver);
  color: #343434;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  height: 52px;
  transition: background-color 0.3s ease;
  user-select: none;
}

@media (hover: hover) and (pointer: fine) {
  .url-shortener button:hover {
    background-color: var(--silver-hover);
    color: #343434;
  }
}

.short-url {
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--snow-drift);
  font-family: "Kumbh Sans", sans-serif;
  font-size: 20px;
}
