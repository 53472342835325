.root-settings {
  display: flex;
  flex-direction: column;
}

.general-spin {
  display: block;
}

.general-spin .general-spinner{
  display: block;
  position: relative;
  top: 4px;
  right: 20px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.general-spin .general-spinner::before {
  animation: .8s linear infinite spinner;
  border-radius: 50%;
  border: solid .25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 2.2em;
  height: 2.2em;
}