.sidebar-tab {
  align-items: center;
  background-color: #282828;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  position: absolute;
  width: 90px;
  height: 100%;

  /* Background */
  background-color: #161616;
  opacity: 1;
  background-image: radial-gradient(#bacddb11 0.75px, transparent 0.75px), radial-gradient(#bacddb11 0.75px, #161616 0.75px);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}

.sidebar-top {
  align-items: center;
  background-color: #1c1c1c;
  border: none;
  border-bottom: solid;
  border-width: 0;
  border-color: var(--dark-grey);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0),
  0px 12px 12px -10px rgba(186, 205, 219, 0.1),
  0px -10px 10px -10px rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 6px;
  position: fixed;
  width: 90px;
  height: 90px;
  z-index: 2;
}

.author-img {
  border-radius: 100%;
  cursor: pointer;
  width: 48px;
}

.sidebar-bottom {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 104px;
}

.guild-title {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  width: 100%;
}

.dropdown-list {
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 2px;
  box-shadow: 0 0 8px 0.4px rgba(186, 205, 219, 0.1);
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  margin: 0;
  padding-top: 1px;
  padding-bottom: 1px;
  top: 60px;
  left: 0;
  height: auto;
  width: 120px;
}

.dropdown-list p {
  border-radius: 2px;
  border-color: #4e4e4e;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 0.6px;
  color: var(--snow-drift);
  cursor: pointer;
  margin: 0;
  text-align: center;
  height: 24px;
  width: 100%;
}

.dropdown-list p:hover {
  background-color: #303030;
}

.dropdown-list p:active {
  background-color: #3d3d3d;
}

.show-dropdown-list {
  display: flex;
}

.guild {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 58px;
  height: 58px;
}

.guild-img {
  cursor: pointer;
  border-radius: 100%;
  margin: 0.3em;
  width: 3.4em;
  transition: border-radius .2s, box-shadow .2s;
}

.guild-img:hover {
  border-radius: 30%;
  box-shadow: 0px 0px 12px 2px rgba(186, 205, 219, 0.08),
  inset 0px 0px 12px 2px rgba(186, 205, 219, 0.08);
}

.guild-img-active {
  align-items: center;
  border-radius: 100%;
  box-shadow: 0px 0px 12px 2px rgba(186, 205, 219, 0.08),
  inset 0px 0px 12px 2px rgba(186, 205, 219, 0.08);
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  transition: box-shadow .2s;
}

.guild-img-inactive {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.guild-img-active img {
  border-radius: 30%;
}