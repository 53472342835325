/** Typography **/
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@200;300;400;500;600;700;800;900&display=swap');

#root-dashboard {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-family: 'Kumbh Sans', sans-serif;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

#root-dashboard button {
  border: none;
  font-family: 'Kumbh Sans', sans-serif;
}