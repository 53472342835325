@tailwind base;
@tailwind components;
@tailwind utilities;

/** Typography **/
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@200;300;400;500;600;700;800;900&display=swap");

/** Colors **/
:root {
  --dark-grey: #343434;
  --silver: #bacddb;
  --silver-hover: #bacddbaf;
  --snow-drift: #fcfaf1;
  --foggy-grey: #cacaca;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

body {
  background-color: var(--snow-drift);
  font-family: "Kumbh Sans", sans-serif;
  user-select: none;
  overflow: scroll;
  overflow-x: hidden;
  margin: 0;
  width: 100%;
  height: 100%;

  /* Background */
  background-color: #161616;
  opacity: 1;
  background-image: radial-gradient(#bacddb11 0.75px, transparent 0.75px),
    radial-gradient(#bacddb11 0.75px, #161616 0.75px);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}

#root {
  height: 100%;
}

h1,
h2,
h4,
h5,
h6 {
  color: var(--snow-drift);
}

p,
h3 {
  color: var(--dark-grey);
}

a {
  color: var(--foggy-grey);
  -webkit-user-drag: none;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  padding: 0 0 0 0;
}

a:hover {
  color: #c39c28;
}

a:active {
  color: #80681f;
}

button {
  border: none;
  font-family: "Kumbh Sans", sans-serif;
}
