.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
}

.elden-logo {
  margin-left: 1rem;
  width: 42px;
}

.author-img {
  border-radius: 100%;
  margin-right: 1rem;
  width: 42px;
}