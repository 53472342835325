.root-twitter {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.promocode {
  align-items: start;
  background-color: #343434;
  border-radius: 12px;
  margin-top: 8px;
  padding: 8px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.promocode:hover {
  background-color: #343434a6;
}

.promocode p {
  color: #fcfaf1;
  font-size: 19.2px;
}

.promocode span {
  color: #cacaca;
}

.region-container {
  display: flex;
  align-items: start;
  justify-content: center;
}

.region {
  align-items: start;
  background-color: #343434;
  border-radius: 10px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: auto;
  width: 28rem;
  min-width: 4dvw;
  max-width: 90dvw;
  padding: 10px;
}

.region p {
  font-weight: 500;
  color: var(--foggy-grey);
}

.region-title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1.6px;
  padding: 8px 0 8px 0;
}

.keywords {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  height: fit-content;
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.keyword,
.blacklist {
  align-items: start;
  background-color: #343434;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 0;
  padding: 8px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.blacklist {
  cursor: default;
  margin-bottom: 16px;
}

.keyword:hover,
.blacklist:hover {
  background-color: #343434a6;
}

.blacklist p {
  color: #fcfaf1;
  font-size: 19.2px;
}

.blacklist span {
  color: #cacaca;
}

.name p {
  color: #fcfaf1;
  font-size: 19.2px;
}

.name span {
  color: #cacaca;
}

.whitelist p {
  color: #fcfaf1;
  font-size: 19.2px;
}

.whitelist span {
  color: #cacaca;
}

.turn-onoff-tweeting {
  position: absolute;
  right: 0;
  top: 10;
}

.parameter-checkbox-box {
  display: flex;
  width: 100%;
  justify-content: end;
}

.parameter-checkbox-status {
  color: var(--foggy-grey);
  margin: 0;
}

.parameter-checkbox-box .parameter-checkbox {
  appearance: none;
  background-color: var(--foggy-grey);
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  margin-left: 6px;
  position: relative;
  width: 38px;
}

.parameter-checkbox-box .parameter-checkbox::before {
  cursor: pointer;
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.parameter-checkbox-box .parameter-checkbox,
.parameter-checkbox-box .parameter-checkbox::after {
  transition: all 100ms ease-out;
}

.parameter-checkbox-box .parameter-checkbox::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  cursor: pointer;
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.parameter-checkbox-box input[type="checkbox"] {
  cursor: default;
}

.parameter-checkbox-box .parameter-checkbox:hover {
  background-color: var(--silver-hover);
  transition-duration: 0s;
}

.parameter-checkbox-box .parameter-checkbox:checked {
  background-color: var(--silver);
}

.parameter-checkbox-box .parameter-checkbox:checked::after {
  background-color: #fff;
  left: 20px;
}

.parameter-checkbox-box :focus:not(.focus-visible) {
  outline: 0;
}

.parameter-checkbox-box .parameter-checkbox:checked:hover {
  background-color: var(--silver-hover);
}
