.navbar {
  display: flex;
  flex-direction: column;
}

.navbar-top {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90px;
  height: 90px;
  z-index: 2;
}

.author-img {
  border-radius: 100%;
  cursor: pointer;
  width: 48px;
}

.dropdown-list {
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 2px;
  box-shadow: 0 0 8px 0.4px rgba(186, 205, 219, 0.1);
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  margin: 0;
  padding-top: 1px;
  padding-bottom: 1px;
  top: 72px;
  left: 0;
  height: auto;
  width: 120px;
}

.dropdown-list p {
  border-radius: 2px;
  border-color: #4e4e4e;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 0.6px;
  color: var(--snow-drift);
  cursor: pointer;
  margin: 0;
  text-align: center;
  height: 24px;
  width: 100%;
}

.dropdown-list p:hover {
  background-color: #303030;
}

.dropdown-list p:active {
  background-color: #3d3d3d;
}

.active-dropdown-list {
  display: flex;
}

.navbar-bottom {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 58px;
  z-index: 1;
}

.main-navlink {
  align-items: center;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.navlink {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
}

.navlink-title {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1.6px;
  transition: color 0.2s, font-weight 0.2s;
}

.navlink-active .navlink-title {
  color: var(--snow-drift) !important;
}

.navlink-active img {
  transition: filter 0.2s;
}

.navlink-active img {
  filter: brightness(0) saturate(100%) invert(85%) sepia(3%) saturate(590%)
    hue-rotate(346deg) brightness(116%) contrast(98%);
}

.navlink-inactive .navlink-title {
  color: var(--silver) !important;
}

.barcode-scan-img,
.link-horizontal-img,
.users-img,
.products-img,
.hp-img {
  margin-right: 10px;
  width: 24px;
}

.link-horizontal-slash-img {
  margin-right: 10px;
  width: 26px;
}

.sneaker-img,
.asics-img,
.jdfnl-img {
  margin-right: 10px;
  width: 40px;
}

.squarefourbold-img {
  margin-right: 10px;
  width: 40px;
}

.inventory-img,
.imagemonitor-img {
  margin-right: 10px;
  width: 28px;
}

.refurbish-img {
  margin-top: 6px;
  margin-right: 10px;
  width: 30px;
}

.productquery-img {
  /* margin-top: 6px; */
  margin-right: 10px;
  width: 30px;
}

.adidas-img {
  /* margin-top: 4px; */
  margin-right: 6px;
  width: 42px;
}

.twitter-img {
  /* margin-top: 4px; */
  margin-right: 6px;
  width: 34px;
}

@media (hover: hover) and (pointer: fine) {
  .navlink:hover .navlink-title {
    color: #a1b1be !important;
  }

  .navlink:hover .barcode-scan-img,
  .navlink:hover .link-horizontal-slash-img,
  .navlink:hover .link-horizontal-img,
  .navlink:hover .users-img,
  .navlink:hover .products-img,
  .navlink:hover .sneaker-img,
  .navlink:hover .refurbish-img,
  .navlink:hover .hp-img,
  .navlink:hover .inventory-img,
  .navlink:hover .imagemonitor-img,
  .navlink:hover .productquery-img,
  .navlink:hover .adidas-img,
  .navlink:hover .twitter-img,
  .navlink:hover .asics-img,
  .navlink:hover .squarefourbold-img {
    filter: brightness(0) saturate(100%) invert(70%) sepia(20%) saturate(218%)
      hue-rotate(165deg) brightness(96%) contrast(90%);
  }
}
