/* Default Styles */
.privacy-policy-and-tos {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 1vmin;
  margin-top: 2rem;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;

  pointer-events: auto !important;
  user-select: text !important;
}

.privacy-policy-and-tos a,
.privacy-policy-and-tos p,
.privacy-policy-and-tos h1,
.privacy-policy-and-tos h2,
.privacy-policy-and-tos h3 {
  color: var(--snow-drift);
  font-weight: 500;

  user-select:text !important;
}

.privacy-policy-and-tos li {
  color: var(--snow-drift);
  font-weight: 300;

  user-select: text !important;
}

.privacy-policy-and-tos a {
  font-size: 1.8vmin;
}

.tos-first-section h1 {
  font-size: 4.2vmin;
  font-weight: 500;
}

.tos-first-section h2 {
  font-size: 3.4vmin;
  font-weight: 500;
}

.tos-second-section h3 {
  font-size: 2.4vmin;
  font-weight: 500;
}

.tos-second-section li {
  font-size: 1.8vmin;
  margin-bottom: 0.6vmin;
}

.tos-second-section li:hover {
  color: #d2d2d2;
}